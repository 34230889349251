[data-tab-list] {
    @apply flex pb-3 w-full items-end leading-tight overflow-x-auto;
}

[data-tab-target] {
    @apply appearance-none flex items-center justify-center py-2 px-4 md:mr-2;
    border-width: 0 0 4px 0;
    border-bottom-color: transparent;
    border-radius: 0;
    flex: none;
    min-width: 6rem;
    max-width: 12rem;
}

[data-tab-target]:focus,
[data-tab-target]:focus-visible {
    outline: none;
    background-color: rgba(122, 122, 122, 0.03)
}

[data-tab-target].active {
    font-weight: bold;
    color: var(--color-brand);
    border-bottom-color: currentColor;
}

[data-tab-content] {
    display: none;
    animation: fadeEffect 500ms;
}

[data-tab-content].active {
    display: block;
}

@keyframes fadeEffect {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

