.page-content *,
#modal, #modal * {
    box-sizing: border-box;
}

.no-js .body-content,
.js-css-loaded .body-content {
    opacity: 1;
    transition: opacity 200ms ease-out;
}

@media print {
    #header, #footer {
        display: none;
    }
}

html, body {
    font-family: var(--default-font);
    font-size: 16px;
}

sup {
    top: -0.3em;
    font-size: 1em;
}

address {
    font-style: normal;
    line-height: 1.2em;
}

img {
    -ms-interpolation-mode: bicubic;
    border: none;
}

cite {
    font-style: normal;
}

