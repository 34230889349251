.table-info {
    width: 100%;
    color: var(--color-grey-darkest);

    td, th {
        padding: 0.5rem;
    }

    tr:nth-child(even) {
        background-color: var(--color-near-white);
    }
}

@media screen and (max-width: 768px) {
    .table-vertical-sm {
        th {
            display: none;
        }

        td {
            display: block;
            padding: 0.125rem 0.5rem;
        }

        td:nth-child(1) {
            padding-top: 0.5rem;
        }

        td:nth-last-child(1) {
            padding-bottom: 0.5rem;
        }
    }
}

.table-striped {

}
