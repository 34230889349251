body .optanon-alert-box-wrapper {
    background-color: rgba(56, 56, 56, .95) !important;
}

body .optanon-alert-box-wrapper .optanon-alert-box-bg p {
    color: #c8c9c7 !important;
}

body .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button,
body .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button:before {
    color: #ffffff !important;
}
