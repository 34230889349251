[class^="bg-overlay-"]:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.bg-overlay-dark:before {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
}

.bg-overlay-orange:before {
    background: linear-gradient(0deg, rgba(227, 82, 5, 0.80) 0%, rgba(227, 82, 5, 0.80) 100%);
}
