:root {
    --default-font: 'Source Sans Pro', "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif;
    --serif-font: Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    --title-font: var(--default-font);
    --body-font: var(--default-font);
    --overlay-blur: 3px;
    --overlay-color: rgba(180, 180, 180, 0.6);
    /*
Unicode characters
---------------------------------------------------*/
    --uc-x: '\00D7';
    --uc-x-bold: '\2716';
    --uc-chevron-right: '\3009';
    --uc-chevron-left: '\3008';
    --uc-single-chevron-right: '\203A';
    --uc-single-chevron-left: '\2039';
    --uc-ellipsis: '\2026';

    --ease-cubic: cubic-bezier(0.350, 0.225, 0.350, 1.000);
    --ease-in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
    --ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    --ease-in-out-cubic: cubic-bezier(0.700, 0.000, 0.325, 1.00);

    --gradient-upi-sheen: linear-gradient(45deg, #e35205 10%, #ce1b1b 50%, #ce1b51 97%);
    --gradient-orange-sheen: linear-gradient(196deg, #d23c27 10%, #e35205 45%, #d23126 98%);
    --gradient-green-sheen: linear-gradient(196deg, #64a70b 10%, #86bd20 45%, #64a70b 97%);
    --gradient-blue-sheen: linear-gradient(196deg, #2f80ed 10%, #44a4ee 45%, #2f5eed 97%);
    --gradient-grey-sheen: linear-gradient(196deg, #202020 10%, #424040 45%, #111111 97%);

}
