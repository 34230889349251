/*---------------------------------------------------
Layout
---------------------------------------------------*/
:root {
    --page-content-padding-top--site-menu: 40px;
    --page-content-padding-top--site-header: 80px;

    @media #{$mq-medium} {
        --page-content-padding-top--site-menu: 72px;
        --page-content-padding-top--site-header: 128px;
    }

    @media #{$mq-wide} {
        --page-content-padding-top--site-header: 128px;
    }
}

.no-scroll {
    overflow: hidden;
}

html {
    //scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    font-smooth: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    font-family: var(--default-font) !important;
    min-height: 100%;
    color: var(--body-text-c);
}

body {
    z-index: 6;
    width: 100%;
    flex-direction: column;
    transition: transform 200ms ease;
    overflow-x: hidden;
}

.page-content {
    //padding-top: var(--page-content-padding-top--site-header);
}

.body-content {
    @apply relative h-auto;
    @include clearfix;
    min-height: 79vh;

    @media #{$mq-medium} {
        min-height: 87vh;
    }
}

.pad-for-site-menu-bar {
    padding-top: var(--page-content-padding-top--site-menu);
}

.pad-for-site-header {
    padding-top: var(--page-content-padding-top--site-header);
}

.contain-content {
    @apply w-page-max mx-auto relative;
    @include clearfix;
}

// customized padding for message bar to work with site header
.site-message-bar {
    min-height: 56px;
    // subtracting standard banner height (mobile)
    margin-top: -40px;

    .message-bar {
        // pad left for header logo ornament
        padding-left: 40px;
    }

    @media #{$mq-medium} {
        // subtracting standard banner height (desktop)
        margin-top: -56px;

        .message-bar {
            // pad left for header logo ornament
            padding-left: 120px;
        }
    }

    // class is used for full image heros where less pad top is need to align image with site logo
    &.hidden ~ section .condense--pad-for-site-header {
        margin-top: -40px;
        @media #{$mq-medium} {
            margin-top: -56px;
        }
    }
}

