:root {
    //--button-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
    //--button-shadow-active: inset 0px 1px 2px rgba(0, 0, 0, 0.2), inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    //--button-shadow-focus: 0 1px 7px rgba(39, 46, 114, 0.2), 0 0 2px rgba(39, 46, 114, 0.2), 0 0 1px rgba(39, 46, 114, 1.0);
    //
    //--badge-border-radius: var(--default-border-radius);
    //--button-border-radius: var(--default-border-radius);
    //
    //--badge-background-color--default: var(--color-moon-grey);
    //
    //--button-border-color--default: var(--color-grey-lightest);
    //--button-background-color--default: var(--color-grey-lightest);
    //--button-text-color--default: var(--color-white);
    //--button-border-color-hover--default: var(--color-grey-lightest);
    //--button-background-color-hover--default: var(--color-grey-lightest);
    //--button-text-color-hover--default: var(--color-white);
    //
    //--button-border-color--disabled: var(--color-grey-lighter);
    //--button-background-color--disabled: var(--color-grey-lighter);
    //--button-text-color--disabled: var(--color-white);
    //
    ///* Primary */
    //--button-background-color--primary: var(--color-brand);
    //--button-border-color--primary: var(--button-c);
    //--button-text-color--primary: var(--color-white);
    //--button-background-color-hover--primary: var(--button-c);
    //--button-border-color-hover--primary: var(--button-c);
    //--button-text-color-hover--primary: var(--color-white);
    //
    ///* Secondary */
    //--button-background-color--secondary: var(--color-grey-darker);
    //--button-border-color--secondary: var(--color-grey-darker);
    //--button-text-color--secondary: var(--color-white);
    //--button-background-color-hover--secondary: var(--color-grey);
    //--button-border-color-hover--secondary: var(--color-grey);
    //--button-text-color-hover--secondary: var(--color-white);
    //
    ///* Tertiary */
    //--button-background-color--tertiary: var(--color-white);
    //--button-border-color--tertiary: var(--color-grey);
    //--button-text-color--tertiary: var(--body-text-c);
    //--button-background-color-hover--tertiary: var(--color-white);
    //--button-border-color-hover--tertiary: var(--color-grey);
    --button-text-color-hover--tertiary: var(--link-3-color--hover);
    //
    ///* Reverse */
    //--button-background-color--reverse-dark: transparent;
    //--button-border-color--reverse-dark: var(--color-white);
    //--button-text-color--reverse-dark: var(--color-white);
    //--button-background-color-hover--reverse-dark: var(--color-white);
    //--button-border-color-hover--reverse-dark: var(--color-white);
    //--button-text-color-hover--reverse-dark: var(--color-black);
    //
    ///* Ghosted/Hollow */
    //--button-background-color--ghosted: transparent;
    //--button-border-color--ghosted: transparent;
    //--button-text-color--ghosted: var(--color-near-black);
    //--button-background-color-hover--ghosted: transparent;
    //--button-border-color-hover--ghosted: transparent;
    //--button-text-color-hover--ghosted: var(--link-blue-active);
}

%button-gradient {
    @extend %button-default;
    color: var(--color-white);
    border: 1px solid transparent;
    background-image: linear-gradient(45deg, #e35205 0%, #ce1b1b 20%, #ce1b51 40%, #e35205 90%);
    background-size: 300% 100%;
    background-position: 0;
    transition: background-position 350ms var(--ease-in-out-cubic);

    &:hover, &.hover {
        color: var(--color-white);
        background-position: -95% 0%;
        background-color: var(--color-brand);
    }

    &:active, &.active {
        background-position: -95% 0%;
    }
}

.button.gradient {
    @extend %button-gradient;
}

