/*
configurations
---------------------------------------------------*/
@keyframes menuOutLeft {
    to {
        opacity: 0;
        transform: translateX(-15%) scale(0.8)
    }
}

@keyframes menuCloseFrom {
    from {
        transform: translateX(0) scale(1)
    }
}

@keyframes menuInLeft {
    from {
        opacity: 0;
        transform: translateX(-15%) scale(0.8)
    }
    to {
        opacity: 1;
        transform: translateX(0) scale(1)
    }
}

@keyframes menuInRight {
    from {
        opacity: 0;
        transform: translateX(100%) scale(1)
    }
    to {
        opacity: 1;
        transform: translateX(0) scale(1)
    }
}

@keyframes menuOpenFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100%) scale(1)
    }
    to {
        opacity: 1;
        transform: translateX(0) scale(1)
    }
}

[data-overlay] {
    &:after {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 29;
        pointer-events: none;
        background: var(--overlay-color);
        opacity: 0;
        transition: opacity 400ms ease-out;
    }
}

[data-overlay="active"]:after {
    opacity: 1;
    pointer-events: unset;
}

.nav-scroller {
    @apply absolute bottom-0 pt-2 pb-10 w-full overflow-x-hidden overflow-y-auto;
    top: 40px;
    -webkit-overflow-scrolling: touch;

    @media #{$mq-medium} {
        top: 72px;
    }
}

.nav-divider {
    border-top: 1px solid rgba(255, 255, 255, .125);
}

.nav-site-menu--wrap {
    visibility: hidden;
    pointer-events: none;
    transition: opacity 200ms ease-out, visibility 0ms 200ms;
    height: 100%;

    .js-site-menu--open & {
        pointer-events: all;
        opacity: 1;
        visibility: visible;
        transition: opacity 200ms ease-out;
    }
}

.js-site-menu--open {
    // disable scrolling on <HTML/>
    &, & body {
        @include no-scroll;
        pointer-events: none;
    }

    #site-header, #page-content, #footer--main {
        filter: blur(var(--overlay-blur));
    }
}

.nav-site-menu--inner {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
    width: 320px;
    height: 100%;
}

.nav-site-menu-left {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 320px;
    height: 100%;
    background: url(../../assets/svg/src/backgrounds/nav-menu-left-bg.svg), var(--gradient-upi-sheen);
    background-size: cover;
    z-index: 10;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;

    @media #{$mq-medium} {
        left: auto !important;
    }

    &[data-state="move-from-left"] {
        opacity: 0;
        animation: menuInLeft 250ms var(--ease-out-cubic) 50ms;
    }

    &[data-state="move-from-right"] {
        animation: menuInRight 250ms var(--ease-out-cubic);
    }

    &[data-state="move-offcanvas-right"] {
        animation: menuCloseFrom 200ms var(--ease-in-out-cubic);
        transform: translateX(100%) scale(1);
    }

    &[data-state="move-oncanvas-left"] {
        animation: menuOpenFromLeft 200ms var(--ease-in-out-cubic);
        transform: translateX(0%) scale(1);
    }

    &[data-state="move-offcanvas-left"] {
        animation: menuCloseFrom 200ms var(--ease-in-out-cubic);
        transform: translateX(-100%) scale(1);
    }

    &[data-state="offcanvas-left"] {
        transform: translateX(-100%) scale(1);
    }

    &[data-state="offcanvas-right"] {
        transform: translateX(100%) scale(1);
    }

    &[data-menu-unviewed] {
        display: none;
    }

    &[data-state="inactive"] {
        visibility: hidden;
        animation: menuOutLeft 450ms var(--ease-in-out-cubic);
        transition: visibility 0ms linear 450ms;
    }
}

.navigation-link {
    @apply relative cursor-pointer;
    transition: background-color 300ms ease-out;

    &:hover {
        background-color: rgba(255, 255, 255, .08);
    }
}

.navigation-link:before {
    @apply absolute left-0 top-0 bottom-0;
    content: '';
    width: 4px;
}

.navigation-link.active:before {
    @apply bg-white;
}




