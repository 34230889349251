.modal, .modal:after, .modal-container {
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.modal {
    display: none;
    z-index: 99;

    [role="dialog"] {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        background-color: white;
    }
}

.modal:after {
    content: '';
    background-color: var(--overlay-color);
    backdrop-filter: blur(3px);
    pointer-events: none;
}

.modal-container {
    position: initial;
    z-index: 0;
}

.modal.is-open {
    display: block;
}

@media (min-width: 768px) {
    .modal [role="dialog"] {
        width: 50vw;
    }
}

@media (min-width: 1200px) {
    .modal [role="dialog"] {
        width: 35vw;
    }
}
