
.site-menu-bar {
    background-color: rgba(255, 255, 255, .925);

    @media #{$mq-medium} {
        height: 72px;
    }
}

.site-header {
    transition: transform 300ms var(--ease-out-cubic);
    transform: translateY(0%);
}

.site-header[data-header-state="offcanvas"] {
    transform: translateY(-100%);
}

.site-logo-alt {
    width: 88px;
    transition: width 320ms 15ms var(--ease-out-cubic);

    @media #{$mq-medium} {
        width: 164px;
    }

    [data-logo-state="offcanvas"] & {
        width: 60px;
    }
}

.site-logo {
    //background-image: url('../../assets/svg/src/backgrounds/logo-upi-corner-edge-left.svg');
    //background-repeat: no-repeat;
    //background-size: contain;
    height: 84px;
    width: 92px;
    transition: transform 220ms var(--ease-out-cubic);
    transform: translateX(0%);

    @media #{$mq-medium} {
        height: 150px;
        width: 165px;
    }

    .js-site-menu--open &,
    [data-logo-state="offcanvas"] & {
        transform: translateX(-100%);
    }

    .upi-logo {
        position: relative;
        top: 10px;
        left: 10px;
        width: 49px;

        @media #{$mq-medium} {
            top: 28px;
            left: 24px;
            width: 72px;
        }
    }
}


